import { FilterMatchMode, FilterType, MapItem } from '@capturum/ui/api';
import { Observable } from 'rxjs';

export interface InfoTableFilterConfig {
  field: string;
  type: FilterType;
  dateType?: DateType;
  label?: string;
  placeholder?: string | Observable<string>;
  icon?: string;
  options?: MapItem[] | Observable<MapItem[]>;
  wrapperStyleClass?: string;
  isSearch?: boolean;
  filterSearchable?: boolean;
  sortAlphabetically?: boolean;
  resettableFilters?: boolean;
  dataTestAttribute?: string;
  matchMode?: FilterMatchMode;
  dateRange?: boolean;
  value?: any;
  globalFilter?: boolean;
}

export enum DateType {
  DATE = 'date',
  DATETIME = 'DATETIME',
}
